
import React from 'react';
import { Link } from 'gatsby';
import Layout from '../../components/layout/Layout';
import FeatureCard from '../../components/FeatureCard';

import featuresData from '../../data/productos/linea-profesional/features-data';
import { StaticImage } from "gatsby-plugin-image"
import SplitSection from '../../components/SplitSection';
import SplitSectionB2b from '../../components/SplitSectionB2b';

import LeadForm from '../../components/LeadForm';
import NewsletterSub from '../../components/NewsletterSub'

const siteVerOpts = [

  {
    label: 'Ecuador',
    value: '',


  },

  {
    label: 'Internacional',
    value: '/',


  },
];

const langOpts = [


  {
    label: 'ES',
    value: '',

  },


  {
    label: 'EN',
    value: `/export/en/products`,

  },


];

const seo={
  title: "Línea Profesional",
  desc:  "Accesorios para la limpieza de pisos, vidrios, paredes en grandes superficies para eliminar el polvo y la suciedad provocada por el tránsito continuo de gente.",
  image: "linea-profesional.jpg",
  pathname: "/linea-profesional",
}
export default function Named ({location}) {


  return(
  <Layout location={location} crumbLabel="Equipos y accesorios profesionales"
  siteVerOpts={siteVerOpts} langOpts={langOpts}
  title={seo.title} desc={seo.desc} banner={seo.image} pathname={seo.pathname}>



<section id="Linea profesional" className="mt-14">
              <div className="">
               <h1 className="text-5xl font-semibold text-center mb-2">Línea Profesional</h1>
                <h2 className="text-4xl font-semibold text-center mb-8 text-primary-lighter">implementos profesionales de limpieza</h2>
              </div>
            </section>



<section id="productos quimicos descripcion" className="mt-14">
<div className="relative bg-blue-900">
  <div className="h-56 bg-blue-900 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
  <StaticImage src="../../images/linea-profesional.jpg"
                      className="flex justify-center "
                      alt="Accesorios resistentes para la limpieza"
                      placeholder="blurred"
                      layout="constrained"
                       />
  </div>
  <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16 pt-28">
    <div className="md:ml-auto md:w-1/2 md:pl-10">
      <p className=" text-xl text-leftfont-normal text-white pb-6">
      <ul className="list-disc ml-8">
                <li>Amplia línea de accesorios para la limpieza de pisos, vidrios y paredes. </li>
                <li>Permiten eliminar de manera eficiente el polvo y la suciedad provocada por el tránsito continuo de gente.</li>
                <li>Accesorios para asegurar entornos limpios y seguros.</li>
              </ul> </p>
      
    </div>
  </div>
</div>
</section>


<section id="categorias productos quimicos" className=" mt-26 bg-gray-200 pt-16 pb-10">
  <h3 className="text-primary-default  title-font text-center text-4xl font-semibold  ">Portafolio de Línea Profesional</h3>
         
    <div className="relative bg-gray-200 px-4 sm:px-6 lg:pt-6 lg:pb-28 lg:px-8">
     <div className="relative max-w-7xl mx-auto mt-10">
      <div className=" max-w-lg mx-auto grid gap-8 lg:grid-cols-3 lg:max-w-none">

        <div className="flex flex-col rounded-lg shadow-lg overflow-hidden ">
          <div className="flex-1 bg-gray-100 p-6 flex flex-col justify-between">
            <div className="flex-1 ">

                <div className="flex flex-wrap mx-auto justify-center ">
                  <h3 className="text-3xl font-semibold text-secondary-lighter text-center leading-relaxed">Mopas de Limpieza</h3>
                </div>
              
               <div className="flex flex-wrap mx-auto justify-left">
                 <Link to="/productos/linea-profesional/mopa-polvo-acrilica" className="mt-4 text-primary-lighter inline-flex items-center text-base text-semibold">Mopa polvo acrílica
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
                 </Link>
                 <Link to="/productos/linea-profesional/mopa-humeda-microfibra-velcro" className="mt-2 text-primary-lighter inline-flex items-center text-base text-semibold">Mopa húmeda microfibra velcro
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
                 </Link>
                 <Link to="/productos/linea-profesional/mopa-vertical-secado" className="mt-2 text-primary-lighter inline-flex items-center text-base text-semibold">Mopa vertical secado
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
                 </Link>
              </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-gray-100 p-6 flex flex-col justify-between">
          <div className="flex-1">
              <div className="flex flex-wrap mx-auto justify-center ">
                  <h3 className="text-3xl font-semibold text-secondary-lighter text-center">Paños de Limpieza</h3>
                </div>
              <div className="flex flex-wrap mx-auto justify-left ">
                <Link to="/productos/linea-profesional/panos-microfibra" className="mt-4 text-primary-lighter inline-flex items-center text-base text-semibold">Paños microfibra
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
               </Link>
              </div>
          </div> 
        </div>
      </div>

      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-gray-100 p-6 flex flex-col justify-between">
          <div className="flex-1">
              <div className="flex flex-wrap mx-auto justify-center ">
                  <h3 className="text-3xl font-semibold text-secondary-lighter text-center">Escurridores</h3>
                </div>
              <div className="flex flex-wrap mx-auto justify-left ">
                <Link to="/productos/linea-profesional/escurridor-para-vidrio" className="mt-4 text-primary-lighter inline-flex items-center text-base text-semibold">Escurridor para vidrio
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
               </Link>
               <Link to="/productos/linea-profesional/escurridor-de-piso" className="mt-4 text-primary-lighter inline-flex items-center text-base text-semibold">Escurridor de piso
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
               </Link>
              </div>
          </div> 
        </div>
      </div>


     </div>
    </div>
  
  </div>
</section>




<section id="features" className="py-10 md:py-20 ">
      <div className="container mx-auto">
        <div className="w-full bg-fill bg-no-repeat bg-products">
          <h2 className="text-3xl font-semibold text-center">¿Por qué elegir nuestros implementos profesionales de limpieza?</h2>
          <p className="md:w-2/3 mx-auto text-center my-5 text-2xl">
          Al elegirnos, usted recibe los siguientes beneficios:
          </p>
        </div>

        <div className="flex flex-wrap justify-center">

            {featuresData.map(feature => (
            <div key={feature.featureName} className="w-full p-4 lg:w-1/3">
              <FeatureCard feature={feature} />
            </div>
            ))}


        </div>
      </div>
</section>

<SplitSectionB2b/>

<LeadForm/>
<NewsletterSub/>


  </Layout>
)}
