


const databits = [
  {
    title: 'Calidad',
    content:
      'Todos nuestros implementos de limpieza  son fabricados con materiales resistentes de larga duración. ',
    
      featureName: 'Absorbentes',
    
    featureImage: '../../images/IconeMateriaPrima.svg'
  },
  {
    title: 'Variedad',
    content:
      'Contamos con una gran variedad de implementos de limpieza para cualquiera de sus necesidades: mopas de barrido, escurridores de piso, coche excurridor, etc. Equipos con diseños modernos y elegantes. ',
    
      featureName: 'Productos amigables con el medio ambiente.',
    
    featureImage: '../../images/IconeVariedad.svg'
  },
  
  {
    title: 'Ahorro de tiempo',
    content:
      ' Su equipo de limpieza podrá ahorrar tiempo en barrido, secado, limpieza y desinfección de amplios espacios.  ',
     
      featureName: 'Productos químicos rendidores, concentrados',
    
    featureImage: '../../images/IconeAhorroTiempo.svg'
  }
  
  

];
export default databits;
